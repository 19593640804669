import Mixins from "../../Mixins.js";

export default {
  name: "AddSaving",
  data() {
    return {
      property: {
        animation: {
          addSaving: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        zakatChartOfAccountId: "",
        pajakChartOfAccountId: "",
        infaqChartOfAccountId: "",
        profitShareChartOfAccountId: "",
        bonusChartOfAccountId: "",
        savingId: "",
        savingCode: "",
        savingName: "",
        currencyId: "",
        profitSharePercent: "",
        balanceMinimum: "",
        initialDepositMinimum: "",
        nextDepositMinimum: "",
        perdayWithdrawMaximum: "",
        depositMultiple: "",
        passiveDays: "",
        isTransactionSlipAutomatic: false,
        isBlockAccount: false,
        nominalNotTaxable: "",
        tax: "",
        zakat: "",
        costAdminMonthly: "",
        costAccountClose: "",
        costBookChange: "",
        costCurrentAccountPrint: "",
        costAccountPassive: "",
        costBalanceMinimum: "",
        daysNotPrinted: "",
        isProfitAdminSame: false,
        isFreeAdmin: false,
        balanceMinimumFreeAdmin: "",
        isCloseBalanceMinimum: "",
        zakatAccountNumber: "",
        infaqAccountNumber: "",
        accountStatus: "",
        nisbahSpecial: "",
        savingAkadTypeId: "",
        savingAppreciateTypeId: "",
        savingBaseCalculateId: "",
        savingMediaPrintId: "",
        savingTypeId: "",
        savingProfitShareAllocationId: "",
        statusId: "",
        productCategoryId: "",
        savingNisbahTypeId: "",
        chartOfAccountId: "",
      },
      options: {
        jenisProduk: [],
        jenisApresiasi: [],
        dasarPerhitungan: [],
        mediaCetakTransaksi: [],
        jenisAkadTabungan: [],
        jenisTabungan: [],
        alokasiBagiHasil: [],
        mataUang: [],
        parameterCoaList: [],
      },
    };
  },
  methods: {
    routeToPageListSaving() {
      this.$router.push("list");
    },
    addSaving() {
      const payload = {
        savingId: this.dataForm.savingId,
        savingCode: this.dataForm.savingCode,
        savingName: this.dataForm.savingName,
        currencyId: this.dataForm.currencyId,
        profitSharePercent: this.dataForm.profitSharePercent,
        balanceMinimum: this.dataForm.balanceMinimum,
        initialDepositMinimum: this.dataForm.initialDepositMinimum,
        nextDepositMinimum: this.dataForm.nextDepositMinimum,
        perdayWithdrawMaximum: this.dataForm.perdayWithdrawMaximum,
        depositMultiple: this.dataForm.depositMultiple,
        passiveDays: this.dataForm.passiveDays,
        isTransactionSlipAutomatic: this.dataForm.isTransactionSlipAutomatic,
        isBlockAccount: this.dataForm.isBlockAccount,
        nominalNotTaxable: this.dataForm.nominalNotTaxable,
        tax: this.dataForm.tax,
        zakat: this.dataForm.zakat,
        costAdminMonthly: this.dataForm.costAdminMonthly,
        costAccountClose: this.dataForm.costAccountClose,
        costBookChange: this.dataForm.costBookChange,
        costCurrentAccountPrint: this.dataForm.costCurrentAccountPrint,
        costAccountPassive: this.dataForm.costAccountPassive,
        costBalanceMinimum: this.dataForm.costBalanceMinimum,
        daysNotPrinted: this.dataForm.daysNotPrinted,
        isProfitAdminSame: this.dataForm.isProfitAdminSame,
        isFreeAdmin: this.dataForm.isFreeAdmin,
        balanceMinimumFreeAdmin: this.dataForm.balanceMinimumFreeAdmin,
        isCloseBalanceMinimum: this.dataForm.isCloseBalanceMinimum,
        zakatAccountNumber: this.dataForm.zakatAccountNumber,
        infaqAccountNumber: this.dataForm.infaqAccountNumber,
        accountStatus: this.dataForm.accountStatus,
        nisbahSpecial: this.dataForm.nisbahSpecial,
        savingAkadTypeId: this.dataForm.savingAkadTypeId,
        savingAppreciateTypeId: this.dataForm.savingAppreciateTypeId,
        savingBaseCalculateId: this.dataForm.savingBaseCalculateId,
        savingMediaPrintId: this.dataForm.savingMediaPrintId,
        savingTypeId: this.dataForm.savingTypeId,
        savingProfitShareAllocationId: this.dataForm
          .savingProfitShareAllocationId,
        statusId: this.dataForm.statusId,
        productCategoryId: this.dataForm.productCategoryId,
        savingNisbahTypeId: this.dataForm.savingNisbahTypeId,
        chartOfAccountId: this.dataForm.chartOfAccountId,
        zakatChartOfAccountId: this.dataForm.zakatChartOfAccountId,
        pajakChartOfAccountId: this.dataForm.pajakChartOfAccountId,
        profitShareChartOfAccountId: this.dataForm.profitShareChartOfAccountId,
        bonusChartOfAccountId: this.dataForm.bonusChartOfAccountId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Produk Tabungan ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addSaving.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "saving",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  this.$store.dispatch(
                    "SET_IDENTIFIER_SAVING_ID",
                    resp.data.data.savingId
                  );
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addSaving.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async getReferenceProductCategory() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_PRODUCT_CATEGORY"
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.productCategoryId;
            const text = i.productCategoryName;
            this.options.jenisProduk.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceSavingAppreciate() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-appreciate-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.appreciateTypeId;
            const text = i.appreciateTypeName;
            this.options.jenisApresiasi.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceSavingMediaPrint() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-media-print",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = i.mediaPrintName;
            const value = i.mediaPrintId;
            this.options.mediaCetakTransaksi.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceSavingAkadType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.akadTypeId;
            const text = i.akadTypeName;
            this.options.jenisAkadTabungan.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceSavingType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.savingTypeId;
            const text = i.savingTypeName;
            this.options.jenisTabungan.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceBaseCalculate() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-base-calculate",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.baseCalculateId;
            const text = i.baseCalculateName;
            this.options.dasarPerhitungan.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceSavingProfitShareAllocation() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-profit-share-allocation",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.profitShareAllocationId;
            const text = i.profitShareAllocationName;
            this.options.alokasiBagiHasil.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceCurrency() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              currencyName: "",
              page: 0,
            },
            url: "m-currency",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = i.currencyName;
            const value = i.currencyId;
            this.options.mataUang.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceParameterChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              description: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.chartOfAccountCode} - ${index.description}`;
            const value = index.chartOfAccountId;
            this.options.parameterCoaList.push({ text, value });
          });
        }
      } catch (error) {}
    },
    appendDefaultInput() {
      this.dataForm.productCategoryId = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_PRODUCT_CATEGORY_SAVING;
      this.dataForm.savingNisbahTypeId = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_SAVING_DEFAULT_SAVING_NISBAH_TYPE;
      this.dataForm.statusId = this.dataForm.savingNisbahTypeId = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_SAVING_DEFAULT_STATUS;
    },
    automationSkipper() {
      const toastBuefy = this.$buefy.toast.open(
        this.$NotificationUtils.success
      );
      this.$store.dispatch("SET_IDENTIFIER_SAVING_ID", "18");
    },
  },
  mounted() {
    this.appendDefaultInput();
    this.getReferenceProductCategory();
    this.getReferenceSavingAppreciate();
    this.getReferenceSavingMediaPrint();
    this.getReferenceSavingAkadType();
    this.getReferenceBaseCalculate();
    this.getReferenceSavingType();
    this.getReferenceSavingProfitShareAllocation();
    this.getReferenceCurrency();
    this.getReferenceParameterChartOfAccount();
  },
};
