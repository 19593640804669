export default {
  data() {
    return {
      MIXINS_DATA: {
        identifier: {
          savingId: this.$store.getters["get_identifier_saving_id"],
        },
      },
    };
  },
  computed: {
    mixin_data_identifier_saving_id() {
      return this.$store.getters["get_identifier_saving_id"];
    },
  },

  methods: {},
};
